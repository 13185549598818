<template>
    <div class="container">
      <!-- Table -->
      <div class="row justify-content-center">
        <div class="col">
            <el-form ref="form" :model="model" :show-message="false" label-width="auto" label-position="top" v-loading="loading">
                <div class="row">
                    <div class="col-md-3">
                    <label class="form-label required">区域</label>
                    <el-form-item prop="district" :rules="[{required:true}]">
                        <el-select v-model="model.district" placeholder="选择区域">
                            <el-option v-for="d in options.districts" :key="d" :label="d" :value="d"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                    <div class="col-md-6">
                    <label class="form-label required">经营主体</label>
                    <el-form-item prop="company" :rules="[{required:true}]">
                        <el-input v-model="model.company" placeholder="填写公司名称" required></el-input>
                    </el-form-item>
                    </div>
                    <div class="col-md-3">
                    <label class="form-label required">公司性质</label>
                    <el-form-item prop="companyType" :rules="[{required:true}]">
                        <el-select v-model="model.companyType" placeholder="选择性质">
                            <el-option label="政府" value="政府"></el-option>
                            <el-option label="企业" value="企业"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                    <label class="form-label required">专柜类型</label>
                    <el-form-item prop="deviceType" :rules="[{required:true}]">
                        <el-select v-model="model.deviceType" placeholder="选择专柜类型">
                            <el-option label="开门式" value="开门式"></el-option>
                            <el-option label="封闭式" value="封闭式"></el-option>
                            <el-option label="有人值守" value="有人值守"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                    <div class="col-md-9">
                    <label class="form-label required">经营地址/投放地点</label>
                    <el-form-item prop="address" :rules="[{required:true}]">
                        <el-input v-model="model.address" placeholder="填写详细地址" required></el-input>
                    </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                    <label class="form-label required">专柜生产厂家</label>
                    <el-form-item prop="deviceProducer" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.deviceProducer" placeholder="填写厂家名称"
                            :fetch-suggestions="queryDP">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                    <div class="col-md-6">
                    <label class="form-label required">技术服务商名称、联系人、电话</label>
                    <el-form-item prop="tecSupport" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.tecSupport" placeholder="填写技术服务商信息"
                            :fetch-suggestions="queryTS">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                </div>
                <hr class="mt-2 mb-3" />
                <div class="row">
                    <div class="col-md">
                    <label class="form-label required">占地面积</label>
                    <el-form-item prop="size" :rules="[{required:true}]">
                        <el-input v-model="model.size" placeholder="填写占地面积" required></el-input>
                    </el-form-item>
                    </div>
                    <div class="col-md">
                    <label class="form-label required">专柜数量</label>
                    <el-form-item prop="quantity" :rules="[{required:true}]">
                        <el-input v-model="model.quantity" type="number" placeholder="填写数量" required></el-input>
                    </el-form-item>
                    </div>
                    <div class="col-md">
                    <label class="form-label required">扶贫产品类型</label>
                    <el-form-item prop="productType" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.productType" placeholder="填写商品类型"
                            :fetch-suggestions="queryPT">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                    <label class="form-label required">收银系统厂商</label>
                    <el-form-item prop="cashierProducer" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.cashierProducer" placeholder="填写收银系统厂商信息"
                            :fetch-suggestions="queryCP">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                    <div class="col-md">
                    <label class="form-label required">经营主体开户银行</label>
                    <el-form-item prop="bankAccount" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.bankAccount" placeholder="填写开户银行"
                            :fetch-suggestions="queryBA">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                    <div class="col-md">
                    <label class="form-label required">专柜支付方式</label>
                    <el-form-item prop="payments" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.payments" placeholder="填写支付方式"
                            :fetch-suggestions="queryPM">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                </div>
                <hr class="mt-2 mb-3" />
                <div class="row">
                    <div class="col-md-3">
                    <label class="form-label required">联系人</label>
                    <el-form-item prop="ownerName" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.ownerName" placeholder="填写联系人姓名"
                            :fetch-suggestions="queryON">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                    <div class="col-md-3">
                    <label class="form-label required">联系电话</label>
                    <el-form-item prop="ownerPhone" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.ownerPhone" placeholder="填写电话号码"
                            :fetch-suggestions="queryOP">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                    <div class="col-md-6">
                    <label class="form-label">备注</label>
                    <el-form-item>
                        <el-input v-model="model.remark"></el-input>
                    </el-form-item>
                    </div>
                </div>
                <div class="text-center">
                    <button type="button" class="btn btn-primary mt-4 ls-3" @click="submit">保存</button>
                    <button type="button" class="btn btn-secondary mt-4 ls-3" @click="cancel">取消</button>
                </div>
            </el-form>
        </div>
      </div>
    </div>
</template>

<script>
//import { Form, FormItem, Autocomplete} from 'element-ui';
import utils from '@/utils'
export default {
    // components: {
    //     [Form.name]: Form,
    //     [FormItem.name]: FormItem,
    //     [Autocomplete.name]: Autocomplete
    // },
    props: {
        modeldata: {
            type: Object,
            description: "the counter object to update",
            default: null
        },
        list: {
            type: Array,
            description: "the saved counters",
            default: null
        },
        saved: {
            type: Boolean,
            description: "indicating data saved",
            default: false
        },
        cancelled: {
            type: Boolean,
            description: "indicating wether editing is cancelled",
            default: false
        }
    },
    data() {
      return {
        tmp_modeldata: this.modeldata,
        tmp_saved: this.saved,
        tmp_cancelled: this.cancelled,
        loading: false,
        model: {
            id: null,
            district: '',
            companyType: '', company: '',
            deviceProducer: '', tecSupport: '', deviceType: '',
            address: '',
            size: '', quantity: '',
            productType: '',
            cashierProducer: '', bankAccount: '', payments: '',
            ownerName: '', ownerPhone: '',
            remark: ''
        },
        options:{
            districts: ["广州市/白云区","广州市/越秀区","广州市/天河区","广州市/海珠区","广州市/荔湾区","广州市/番禺区","广州市/南沙区","广州市/黄埔区","广州市/花都区","广州市/从化区","广州市/增城区"]
        }
      }
    },
    computed: {
        profiles() {
            return this.$store.state.profiles.profiles || {}
        }
    },
    watch: {
        modeldata(v) {
            // if "modeldata" changed, means another Counter is passed to be edit
            // we need to refresh the form config
            this.copyData(v);
            this.tmp_cancelled = false;
            this.tmp_saved = false;
        },
        tmp_modeldata(v) {
            this.$emit("update:modeldata", v);
        },
        tmp_saved(v) {
            // telling parents component the data is saved
            this.$emit("update:saved", v);
        },
        tmp_cancelled(v) {
            // telling parents component user cancelled editing
            this.$emit("update:cancelled", v);
        }
    },
    methods: {
        query(field, query, callback) {
            var res = query ? this.list.filter((x)=>{ return x[field].indexOf(query) >= 0 }) : this.list;
            res = res.map((x)=>{ return { value: x[field] } })
            callback(utils.distinct(res))
        },
        queryCP(query, cb) { this.query('cashier_producer', query, cb); },
        queryBA(query, cb) { this.query('bank_account', query, cb); },
        queryPM(query, cb) { this.query('payments', query, cb); },
        queryDP(query, cb) { this.query('device_producer', query, cb); },
        queryTS(query, cb) { this.query('tec_support', query, cb); },
        queryPT(query, cb) { this.query('product_type', query, cb); },
        queryON(query, cb) { this.query('owner_name', query, cb); },
        queryOP(query, cb) { this.query('owner_phone', query, cb); },
        copyData(v) {
            if(v) {
                var stage = this.model, data = v;
                stage.id = data.id;
                stage.district = data.district;
                stage.companyType = data.company_type;
                stage.company = data.company;
                stage.deviceProducer = data.device_producer;
                stage.tecSupport = data.tec_support;
                stage.deviceType = data.device_type;
                stage.address = data.address;
                stage.size = data.size;
                stage.quantity = data.quantity;
                stage.productType = data.product_type;
                stage.cashierProducer = data.cashier_producer;
                stage.bankAccount = data.bank_account;
                stage.payments = data.payments;
                stage.ownerName = data.owner_name;
                stage.ownerPhone = data.owner_phone;
                stage.remark = data.remark;
            }
        },
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.save();
                } else {
                    return false;
                }
            });
        },
        save() {
            this.loading = true;
            this.axios({
                method: this.model.id ? 'put' : 'post', // put for update, post for create
                url: this.model.id ? 'counters/' + this.model.id : 'counters',
                data: this.model
            }).then((res) => {
                this.loading = false;
                if (res.data.errcode == 0) {
                    this.tmp_saved = true;
                }
                else {
                    this.$notifyErr('保存失败', res.data.errmsg);
                }
            }).catch((err) => {
                if(err.response) {
                    if(err.response.data.error) this.$notifyErr('保存失败', err.response.data.error.message);
                    else this.$notifyErr('保存失败', err.response.data[0].message);
                }
                else this.$notifyErr('保存失败', 'Failed: ' + err.message);
                this.loading = false;
            })
        },
        cancel() {
            this.tmp_cancelled = true
        }
    },
    mounted() {
        this.tmp_cancelled = false;
        this.tmp_saved = false;
        this.copyData(this.tmp_modeldata);
        if(!this.tmp_modeldata) this.model.company = this.profiles.company;
    }
};
</script>
<style scoped>
    .el-select, .el-autocomplete {
        width: 100%;
    }
    label.required::before {
        content: '*';
        color: red;
        position: absolute;
        top: 0;
        left: 6px;
        font-weight: 300;
        font-size: smaller;
    }
</style>